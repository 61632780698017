import { createSlice } from "@reduxjs/toolkit";
import * as action from "./reportAction";
export const ReportDataSlice = createSlice({
  name: "ReportData",
  initialState: {
    getLoanByReport: [],
    getEmiByReport: [],
    getCommission: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(action.GetLoanByReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(action.GetLoanByReport.fulfilled, (state, action) => {
        state.isLoading = false;

        state.getLoanByReport = action.payload.getLoanByReport;
      })
      .addCase(action.GetLoanByReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(action.GetEmiByReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(action.GetEmiByReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getEmiByReport = action.payload.getEmiByReport;
      })
      .addCase(action.GetEmiByReport.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(action.GetCommission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(action.GetCommission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getCommission = action.payload.getCommission;
      })
      .addCase(action.GetCommission.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default ReportDataSlice.reducer;
