import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  attachmentApiEndPoint,
  AdvertisementApiEndPoint,
  baseurl,
} from "../../configs/serverApiConfig";

export const GetAdvertisement = createAsyncThunk(
  "/CMS/GetAdvertisement",
  async (thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl.adminServiceBaseURl}${AdvertisementApiEndPoint.Get}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAdvertisement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAdvertisementBySlug = createAsyncThunk(
  "/CMS/GetAdvertisementBySlug",
  async (data) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl.adminServiceBaseURl}${AdvertisementApiEndPoint.GetAdvertisementBySlug}/${data?.slug}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAdvertisementBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddAdvertisement = createAsyncThunk(
  "/CMS/AddAdvertisement",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl.adminServiceBaseURl}${AdvertisementApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { addAdvertisement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateAdvertisement = createAsyncThunk(
  "/CMS/UpDateAdvertisement",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl.adminServiceBaseURl}${AdvertisementApiEndPoint.Update}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { upDateAdvertisement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteAdvertisement = createAsyncThunk(
  "/CMS/DeleteAdvertisement",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl.adminServiceBaseURl}${AdvertisementApiEndPoint.DeleteAdvertisement}/${data}`,
        {
          data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteAdvertisement: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UploadFileThumbnail = createAsyncThunk(
  "/Admin/UploadFileThumbnail",
  async (UploadFiledata, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${baseurl.adminServiceBaseURl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadFileThumbnail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
