import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  attachmentApiEndPoint,
  baseurl,
  cmsApiEndPoint,
} from "../../configs/serverApiConfig";
import { Get } from "../../components/api/api";

const accessToken = localStorage.getItem("accessToken");
// export const GetCMS = createAsyncThunk("/CMS/GetCMS", async (thunkAPI) => {
//   try {
//     const response = await axios.get(
//       `${baseurl.adminServiceBaseURl}${cmsApiEndPoint.GetCMS}`,
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     );
//     console.log(response, "response**");
//     return { getCMS: response?.data };
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.response.data);
//   }
// });
export const GetCMS = createAsyncThunk("/CMS/GetCMS", async () => {
  return Get(`${baseurl.adminServiceBaseURl}${cmsApiEndPoint.GetCMS}`);
});
export const GetCMSBySlug = createAsyncThunk(
  "/CMS/GetCMSBySlug",
  async (DataOfSlug, thunkAPI) => {
    const payload = {
      slug: DataOfSlug,
    };
    try {
      const response = await axios.get(
        `${baseurl.adminServiceBaseURl}${cmsApiEndPoint.GetCMSBySlug}/${DataOfSlug}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCMSBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const Uploadfile = createAsyncThunk(
  "/Admin/uploadfile",
  async (UploadFiledata, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${baseurl?.customerBaseURL}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadfile: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpdateCms = createAsyncThunk(
  "/CMS/UpdateCms",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.adminServiceBaseURl}${cmsApiEndPoint.UpdateCms}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { updateCms: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UploadImg = createAsyncThunk(
  "/Admin/uploadImg",
  async (UploadImageData, thunkAPI) => {
    // console.log(UploadImageData, "index");
    try {
      const apiurl = process.env.REACT_APP_API_BASEURL;
      const formData = new FormData();
      formData.append("file", UploadImageData?.img);
      const response = await axios.post(
        `${apiurl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const returnResponse = {
        img: response?.data,
        indexData: UploadImageData?.indexImg,
      };
      return { uploadImg: returnResponse };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UploadFileThumbnail = createAsyncThunk(
  "/Admin/UploadFileThumbnail",
  async (UploadFiledata, thunkAPI) => {
    try {
      // const apiurl = process.env.REACT_APP_API_BASEURL;
      const formData = new FormData();
      formData.append("filename[]", UploadFiledata);
      const response = await axios.post(
        `${baseurl?.authBaseURL}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
            // Set the content type explicitly
          },
        }
      );
      return { uploadFileThumbnail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddCMS = createAsyncThunk(
  "/CMS/AddCMS",
  async (data, thunkAPI) => {
    try {
      // const apiurl = process.env.REACT_APP_API_BASEURL;
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl.adminServiceBaseURl}${cmsApiEndPoint.AddCMS}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { addCMS: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const DeleteCMS = createAsyncThunk(
  "/CMS/DeleteCMS",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl.adminServiceBaseURl}${cmsApiEndPoint.DeleteCMS}/${data} `,
        {
          // data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteBlog: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
