import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  adminProfileApiEndPoint,
  attachmentApiEndPoint,
  baseurl,
  dashboardApiEndPoint,
} from "../../configs/serverApiConfig";
// import { handleLogout } from "@store/auth/authSlice";

const ApiUrl = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");
export const GetAllEmiByMissing = createAsyncThunk(
  "/Loan/GetAllEmiByMissing",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.customerBaseURL}${
          dashboardApiEndPoint.GetAllEmiByMissing
        }?page=${data?.page}&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAllEmiByMissing: response?.data };
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetNofication = createAsyncThunk(
  "Notification/GetNotificationByUser",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${ApiUrl}${dashboardApiEndPoint.GetNotificationByUser}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getNofication: response?.data };
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const ReadNotification = createAsyncThunk(
  "/Notification/ReadNotification",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${ApiUrl}${dashboardApiEndPoint.ReadNotification}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { readNotification: response?.data };
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const UploadFile = createAsyncThunk(
  "/Admin/uploadfile",
  async (UploadFiledata, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${ApiUrl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadFile: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "/Admin/changePassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${adminProfileApiEndPoint?.ChangePassword}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const ForgotPassword = createAsyncThunk(
  "/Admin/ForgotPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${adminProfileApiEndPoint?.ForgotPassword}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const ForgotPasswordWithToken = createAsyncThunk(
  "/Admin/ForgotPasswordWithToken",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.authBaseURL}${adminProfileApiEndPoint?.ForgotPasswordWithToken}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const EarningReport = createAsyncThunk(
  "/Admin/EarningReport",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl?.customerBaseURL}${dashboardApiEndPoint.EarningReportEnd}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { earningReport: response?.data };
    } catch (error) {
      // Handle errors here
      if (error?.response.status === 401) {
        // console.log("helloLogOut");
        // thunkAPI.dispatch(handleLogout());
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
