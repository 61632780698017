import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./blogAction";

export const BlogPagesDataSlice = createSlice({
  name: "BlogPagesData",
  initialState: {
    getBlog: [],
    addBlog: [],
    getBlogBySlug: [],
    upDateBlog: [],
    deleteBlog: [],
    uploadFileThumbnail: [],
    isLoading: false,
  },
  reducers: {
    resetAddBlog: (state) => {
      state.addBlog = [];
    },
    resetUpdateBlog: (state) => {
      state.upDateBlog = [];
    },

    resetEditBlogData: (state) => {
      state.getBlogBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(actions.GetBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetBlog.fulfilled, (state, action) => {
        state.getBlog = action.payload.getBlog;
        state.isLoading = false;
      })

      .addCase(actions.GetBlogBySlug.fulfilled, (state, action) => {
        state.getBlogBySlug = action.payload.getBlogBySlug;
      })

      .addCase(actions.AddBlog.fulfilled, (state, action) => {
        state.addBlog = action.payload.addBlog;
      })

      .addCase(actions.UpDateBlog.fulfilled, (state, action) => {
        state.upDateBlog = action.payload.upDateBlog;
      })

      .addCase(actions.DeleteBlog.fulfilled, (state, action) => {
        state.deleteBlog = action.payload.deleteBlog;
      })
      .addCase(actions.UploadFileThumbnail.fulfilled, (state, action) => {
        state.uploadFileThumbnail = action.payload.uploadFileThumbnail;
      });
  },
});
export const { resetAddBlog } = BlogPagesDataSlice.actions;
export const { resetUpdateBlog } = BlogPagesDataSlice.actions;
export const { resetEditBlogData } = BlogPagesDataSlice.actions;
export default BlogPagesDataSlice.reducer;
