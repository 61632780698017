import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  attachmentApiEndPoint,
  baseurl,
  newsApiEndPoint,
} from "../../configs/serverApiConfig";

export const UploadFileThumbnail = createAsyncThunk(
  "/Admin/UploadFileThumbnail",
  async (UploadFiledata, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("file", UploadFiledata);
      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${attachmentApiEndPoint.UploadFile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type explicitly
          },
        }
      );
      return { uploadFileThumbnail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetNews = createAsyncThunk("/CMS/GetNews", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${baseurl?.adminServiceBaseURl}${newsApiEndPoint.Get}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { getNews: response?.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const GetNewsBySlug = createAsyncThunk(
  "/CMS/GetNewsBySlug",
  async (data) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${newsApiEndPoint.GetNewsBySlug}/${data?.slug}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getNewsBySlug: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const AddNews = createAsyncThunk(
  "/CMS/AddNews",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${newsApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { addNews: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateNews = createAsyncThunk(
  "/CMS/UpDateNews",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${newsApiEndPoint.Update}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { upDateNews: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const DeleteNews = createAsyncThunk(
  "/CMS/DeleteNews",
  async (data, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${newsApiEndPoint.DeleteNews}/${data}`,
        {
          data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteNews: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
