import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./profileManagementAction";

export const profileManagementSlice = createSlice({
  name: "profileManagement",
  initialState: {
    updateAdminProfile: [],
    getProfileData: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(actions.UpdateAdminProfile.fulfilled, (state, action) => {
        state.updateAdminProfile = action.payload.updateAdminProfile;
      })
      .addCase(actions.GetProfileDataAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetProfileDataAdmin.fulfilled, (state, action) => {
        state.isLoading = false;

        state.getProfileData = action.payload.getProfileData;
      })
      .addCase(actions.GetProfileDataAdmin.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default profileManagementSlice.reducer;
