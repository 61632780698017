import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./loanManagementAction";
export const LoanManagementSlice = createSlice({
  name: "LoanManagementData",
  initialState: {
    loanManagementData: [],
    getEmiHistory: [],
    getAllUser: [],
    getLoanDetailsByLoanId: [],
    getEmiCalenderByLoanAndUser: [],
    getTransactionByUserIdAndLoan: [],
    getLoanDetailsByUserID: [],
    getLoanList: [],
    getLoanEMICalender: [],
    getLoanEMIHistory: [],
    getLoanAccountNumberByUserID: [],
    isLoadingLoanAccountNumber: false,
    isLoading: false,
    isError: false,
    isLoadingEMICalender: false,
    isErrorEMICalender: false,
    isErrorEMIHistory: false,
    isErrorLoanDetails: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetAllLoanData.fulfilled, (state, action) => {
        state.loanManagementData = action.payload.loanManagementData;
      })
      .addCase(actions.GetEMIHistory.fulfilled, (state, action) => {
        state.getEmiHistory = action.payload.getEmiHistory;
      })
      .addCase(actions.GetLoanDetailsByLoanId.fulfilled, (state, action) => {
        state.getLoanDetailsByLoanId = action.payload.getLoanDetailsByLoanId;
      })
      .addCase(actions.GetLoanList.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(actions.GetLoanList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getLoanList = action.payload.getLoanList;
      })
      .addCase(actions.GetLoanList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(actions.GetLoanDetailsByUserID.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(actions.GetLoanDetailsByUserID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getLoanDetailsByUserID = action.payload.data;
      })
      .addCase(actions.GetLoanDetailsByUserID.rejected, (state) => {
        state.isLoading = false;
        state.isErrorLoanDetails = true;
      })
      .addCase(actions.GetLoanEMICalender.pending, (state) => {
        state.isLoadingEMICalender = true;
        state.isErrorEMICalender = null;
      })
      .addCase(actions.GetLoanEMICalender.fulfilled, (state, action) => {
        state.isLoadingEMICalender = false;
        state.getLoanEMICalender = action.payload.getLoanEMICalender;
      })
      .addCase(actions.GetLoanEMICalender.rejected, (state) => {
        state.isLoadingEMICalender = false;
        state.isErrorEMICalender = true;
      })
      .addCase(actions.GetLoanEMIHistory.pending, (state) => {
        state.isLoadingEMIHistory = true;
        state.getLoanEMIHistory = null;
      })
      .addCase(actions.GetLoanEMIHistory.fulfilled, (state, action) => {
        state.isLoadingEMIHistory = false;
        state.getLoanEMIHistory = action.payload.getLoanEMIHistory;
      })
      .addCase(actions.GetLoanEMIHistory.rejected, (state) => {
        state.getLoanEMIHistory = false;
        state.isErrorEMIHistory = true;
      })
      .addCase(actions.GetLoanAccountNumberByUserID.pending, (state) => {
        state.isLoadingLoanAccountNumber = true;
        state.getLoanAccountNumberByUserID = null;
      })
      .addCase(
        actions.GetLoanAccountNumberByUserID.fulfilled,
        (state, action) => {
          state.isLoadingLoanAccountNumber = false;
          state.getLoanAccountNumberByUserID =
            action.payload.getLoanAccountNumberByUserID;
        }
      )
      .addCase(actions.GetLoanAccountNumberByUserID.rejected, (state) => {
        state.isLoadingLoanAccountNumber = false;
        // state.isError = true;
      });
  },
});

export default LoanManagementSlice.reducer;
