import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./dashboardActions";

export const DashBoardDataSlice = createSlice({
  name: "DashBoardDataSlice",
  initialState: {
    getAllEmiByMissing: [],
    getNofication: [],
    readNotification: [],
    uploadFile: [],
    updateAdminProfile: [],
    getProfileData: [],
    earningReport: [],
    openMenu: true,
    isLoadingEarning: false,
    isLoadingMissingEMI: false,
  },
  reducers: {
    openMenuToggle: (state, action) => {
      state.openMenu = action.payload;
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetAllEmiByMissing.pending, (state) => {
        state.isLoadingMissingEMI = true;
      })
      .addCase(actions.GetAllEmiByMissing.fulfilled, (state, action) => {
        state.isLoadingMissingEMI = false;
        state.getAllEmiByMissing = action.payload.getAllEmiByMissing;
      })
      .addCase(actions.GetAllEmiByMissing.rejected, (state) => {
        state.isLoadingMissingEMI = false;
      })
      .addCase(actions.GetNofication.fulfilled, (state, action) => {
        state.getNofication = action.payload.getNofication;
      })
      .addCase(actions.ReadNotification.fulfilled, (state, action) => {
        state.readNotification = action.payload.readNotification;
      })
      .addCase(actions.UploadFile.fulfilled, (state, action) => {
        state.uploadFile = action.payload.uploadFile;
      })
      .addCase(actions.EarningReport.pending, (state) => {
        state.isLoadingEarning = true;
      })
      .addCase(actions.EarningReport.fulfilled, (state, action) => {
        state.earningReport = action.payload.earningReport;
        state.isLoadingEarning = false;
      })
      .addCase(actions.EarningReport.rejected, (state) => {
        state.isLoadingEarning = false;
      });
  },
});
export const { openMenuToggle, handleMenuCollapsed } =
  DashBoardDataSlice.actions;
export default DashBoardDataSlice.reducer;
