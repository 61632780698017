import { createSlice } from "@reduxjs/toolkit";
import * as action from "./sendNotificationAction";

export const SendNotificationDataSlice = createSlice({
  name: "SendNotificationData",
  initialState: {
    getAllLoanByAdmin: [],
    sendNotificationByEmail: [],
    getCompanyByAdmin: [],
    sendNotificationBySMS: [],
    sendNotificationByUser: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(action.GetNotificationTableAPI.fulfilled, (state, action) => {
        state.getAllLoanByAdmin = action.payload.getAllLoanByAdmin;
      })
      .addCase(action.SendNotificationByEmail.fulfilled, (state, action) => {
        state.sendNotificationByEmail = action.payload.sendNotificationByEmail;
      })
      .addCase(action.SendNotificationBySMS.fulfilled, (state, action) => {
        state.sendNotificationBySMS = action.payload.sendNotificationBySMS;
      })
      .addCase(action.SendNotificationByUser.fulfilled, (state, action) => {
        state.sendNotificationByUser = action.payload.sendNotificationByUser;
      });
  },
});

export default SendNotificationDataSlice.reducer;
