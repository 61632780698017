import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as actions from "./employeeManagementAction";

const selectEmployeeManagement = (state) => state.EmployeeManagementData;

// Select getAllUser data
export const selectGetAllUserData = createSelector(
  [selectEmployeeManagement],
  (employeeManagement) => employeeManagement.getAllUser.data
);

// Select isLoading state
export const selectIsLoading = createSelector(
  [selectEmployeeManagement],
  (employeeManagement) => employeeManagement.isLoading
);

// Select error state
export const selectError = createSelector(
  [selectEmployeeManagement],
  (employeeManagement) => employeeManagement.error
);

export const EmployeeManagementSlice = createSlice({
  name: "EmployeeManagementData",
  initialState: {
    getAllUser: [],
    getAllUserById: [],
    getLoanDetailsByUser: [],
    getNotificationByUserId: [],
    getEmiHistory: [],
    getEmiCalender: [],
    isLoading: false,
    error: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetAllUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actions.GetAllUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getAllUser = action.payload.getAllUser;
      })
      .addCase(actions.GetAllUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(actions.GetAllUserById.fulfilled, (state, action) => {
        state.getAllUserById = action.payload.getAllUserById;
      })
      .addCase(actions.GetLoanDetailsByUser.fulfilled, (state, action) => {
        state.getLoanDetailsByUser = action.payload.getLoanDetailsByUser;
      })
      .addCase(actions.GetNotificationByUserId.fulfilled, (state, action) => {
        state.getNotificationByUserId = action.payload.getNotificationByUserId;
      })
      .addCase(actions.GetEmiHistory.fulfilled, (state, action) => {
        state.getEmiHistory = action.payload.getEmiHistory;
      })
      .addCase(actions.GetEmiCalender.fulfilled, (state, action) => {
        state.getEmiCalender = action.payload.getEmiCalender;
      });
  },
});

export default EmployeeManagementSlice.reducer;
