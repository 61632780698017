import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  accessToken,
  adminProfileApiEndPoint,
  baseurl,
} from "../../configs/serverApiConfig";

export const GetProfileDataAdmin = createAsyncThunk(
  "companyMangeTableData/getProfileData",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.customerBaseURL}${adminProfileApiEndPoint.GetProfile}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getProfileData: response?.data };
    } catch (error) {
      // console.log(error, "error");
      if (error.response.status === 401) {
        localStorage.removeItem("token");
      }
      return thunkAPI.rejectWithValue(error.response.data); // You can handle errors using rejectWithValue
    }
  }
);
export const UpdateAdminProfile = createAsyncThunk(
  "/Admin/UpdateAdminProfile",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl.customerBaseURL}${adminProfileApiEndPoint.UpdateAdminProfile}`,

        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { updateAdminProfile: response?.data };
    } catch (error) {
      // Handle errors here
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
