import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./advertisementAction";

export const AdvertisementPagesDataSlice = createSlice({
  name: "AdvertisementPagesData",
  initialState: {
    getAdvertisement: [],
    addAdvertisement: [],
    getAdvertisementBySlug: [],
    upDateAdvertisement: [],
    deleteAdvertisement: [],
    uploadFileThumbnail: [],
  },
  reducers: {
    resetAddAdvertisement: (state) => {
      state.addAdvertisement = [];
    },
    resetUpdateAdvertisement: (state) => {
      state.upDateAdvertisement = [];
    },

    resetEditAdvertisementData: (state) => {
      state.getAdvertisementBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(actions.GetAdvertisement.fulfilled, (state, action) => {
        state.getAdvertisement = action.payload.getAdvertisement;
      })

      .addCase(actions.GetAdvertisementBySlug.fulfilled, (state, action) => {
        state.getAdvertisementBySlug = action.payload.getAdvertisementBySlug;
      })

      .addCase(actions.AddAdvertisement.fulfilled, (state, action) => {
        state.addAdvertisement = action.payload.addAdvertisement;
      })

      .addCase(actions.UpDateAdvertisement.fulfilled, (state, action) => {
        state.upDateAdvertisement = action.payload.upDateAdvertisement;
      })

      .addCase(actions.DeleteAdvertisement.fulfilled, (state, action) => {
        state.deleteAdvertisement = action.payload.deleteAdvertisement;
      })
      .addCase(actions.UploadFileThumbnail.fulfilled, (state, action) => {
        state.uploadFileThumbnail = action.payload.uploadFileThumbnail;
      });
  },
});
export const { resetAddAdvertisement } = AdvertisementPagesDataSlice.actions;
export const { resetUpdateAdvertisement } = AdvertisementPagesDataSlice.actions;
export const { resetEditAdvertisementData } =
  AdvertisementPagesDataSlice.actions;
export default AdvertisementPagesDataSlice.reducer;
