import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  loanManagementApiEndPoint,
  notificationApiEndPoint,
} from "../../configs/serverApiConfig";

export const GetNotificationTableAPI = createAsyncThunk(
  "/Loan/GetAllLoanByAdmin",
  async (thunkAPI) => {
    const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${API}${loanManagementApiEndPoint.GetAllLoanByAdmin}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAllLoanByAdmin: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const SendNotificationByEmail = createAsyncThunk(
  "/Notification/SendNotificationByEmail",
  async (data, thunkAPI) => {
    const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${API}${notificationApiEndPoint.SendNotificationByEmail}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { sendNotificationByEmail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const SendNotificationByUser = createAsyncThunk(
  "/Notification/BulkNotificationByUser",
  async (data, thunkAPI) => {
    const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${API}${notificationApiEndPoint.BulkNotificationByUser}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { sendNotificationByUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const SendNotificationBySMS = createAsyncThunk(
  "Notification/SendNotificationBySMS",
  async (data, thunkAPI) => {
    const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${API}${notificationApiEndPoint.SendNotificationBySMS}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { sendNotificationBySMS: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
