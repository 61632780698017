import { createSlice } from "@reduxjs/toolkit";
import * as action from "./newsAction";
export const NewsPagesDataSlice = createSlice({
  name: "NewsPagesData",
  initialState: {
    getNews: [],
    uploadFileThumbnail: [],
    addNews: [],
    getNewsBySlug: [],
    upDateNews: [],
    deleteNews: [],
  },
  reducers: {
    resetEditNewsData: (state) => {
      state.getNewsBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(action.GetNews.fulfilled, (state, action) => {
        state.getNews = action.payload.getNews;
      })

      .addCase(action.GetNewsBySlug.fulfilled, (state, action) => {
        state.getNewsBySlug = action.payload.getNewsBySlug;
      })

      .addCase(action.UploadFileThumbnail.fulfilled, (state, action) => {
        state.uploadFileThumbnail = action.payload.uploadFileThumbnail;
      })

      .addCase(action.AddNews.fulfilled, (state, action) => {
        state.addNews = action.payload.addNews;
      })

      .addCase(action.UpDateNews.fulfilled, (state, action) => {
        state.upDateNews = action.payload.upDateNews;
      })

      .addCase(action.DeleteNews.fulfilled, (state, action) => {
        state.deleteNews = action.payload.deleteNews;
      });
  },
});
export const { resetEditNewsData } = NewsPagesDataSlice.actions;
export default NewsPagesDataSlice.reducer;
