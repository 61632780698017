import { createSlice } from "@reduxjs/toolkit";
import * as action from "./lendingPartnerAction";
export const LendingPartnerPagesDataSlice = createSlice({
  name: "LendingPartnerPagesData",
  initialState: {
    getLendingPartner: [],
    addPartner: [],
    deleteLendingPartner: [],
    getLendingPartnerByID: [],
    upDateLendingPartner: [],
  },
  reducers: {
    resetEditLendingPartnerDefaultData: (state) => {
      state.getLendingPartnerByID = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(action.GetLendingPartner.fulfilled, (state, action) => {
        state.getLendingPartner = action.payload.getLendingPartner;
      })

      .addCase(action.GetLendingPartnerByID.fulfilled, (state, action) => {
        state.getLendingPartnerByID = action.payload.getLendingPartnerByID;
      })

      .addCase(action.AddPartner.fulfilled, (state, action) => {
        state.addPartner = action.payload.addPartner;
      })

      .addCase(action.UpDateLendingPartner.fulfilled, (state, action) => {
        state.upDateLendingPartner = action.payload.upDateLendingPartner;
      })
      .addCase(action.DeleteLendingPartner.fulfilled, (state, action) => {
        state.deleteLendingPartner = action.payload.deleteLendingPartner;
      });
  },
});
export const { resetEditLendingPartnerDefaultData } =
  LendingPartnerPagesDataSlice.actions;
export default LendingPartnerPagesDataSlice.reducer;
