
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

export const VerifyUserEmail = createAsyncThunk('/User/VerifyUserEmail', async (data, thunkAPI) => {
  const API = process.env.REACT_APP_API_BASEURL
  try {
    const response = await axios.post(`${API}User/VerifyUserEmail`, data)
    return { verifyUserEmail: response?.data}
  } catch (error) {
     return thunkAPI.rejectWithValue(error.response.data) 
  }
})
export const VerifyOTP = createAsyncThunk('/User/VerifyOTP', async (data, thunkAPI) => {
  const API = process.env.REACT_APP_API_BASEURL
  try {
    const response = await axios.post(`${API}User/VerifyOTP`, data)
    return { verifyOTP: response?.data}
  } catch (error) {
     return thunkAPI.rejectWithValue(error.response.data) 
  }
})
export const ResetPassword = createAsyncThunk('/User/ResetPassword', async (data, thunkAPI) => {
  const API = process.env.REACT_APP_API_BASEURL
  try {
    const response = await axios.post(`${API}User/ResetPassword`, data)
    return { resetPassword: response?.data}
  } catch (error) {
     return thunkAPI.rejectWithValue(error.response.data) 
  }
})

export const resetALLStoreAPIdata = createAction('ResetPassword/resetALLStoreAPIdata')

export const ForgotPassWordDataSlice = createSlice({
  name: 'ForgotPassWordData',
  initialState: {
    verifyUserEmail:[],
    verifyOTP:[],
    setEmailVerify: false,
    setEmail:'',
    resetPassword:[]
  },
 reducers: {
    SetEmailVerify: (state, action) => {
      state.setEmailVerify = action.payload
    },
    SetEmail: (state, action) => {
      state.setEmail = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(VerifyUserEmail.fulfilled, (state, action) => {
      state.verifyUserEmail = action.payload.verifyUserEmail
      })
    .addCase(VerifyOTP.fulfilled, (state, action) => {
      state.verifyOTP = action.payload.verifyOTP
      })
    .addCase(ResetPassword.fulfilled, (state, action) => {
      state.resetPassword = action.payload.resetPassword
      })
      .addCase(resetALLStoreAPIdata, (state) => {
        state.verifyUserEmail = []
        state.verifyOTP = []
        state.resetPassword = []
        state.setEmailVerify = false
        })  
  }
})
export const { SetEmailVerify } = ForgotPassWordDataSlice.actions
export const { SetEmail } = ForgotPassWordDataSlice.actions
export default ForgotPassWordDataSlice.reducer
