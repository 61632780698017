import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./cmsAction";

export const CMSPagesDataSlice = createSlice({
  name: "CMSPagesData",
  initialState: {
    getCMS: [],
    getCMSBySlug: [],
    uploadfile: [],
    updateCms: [],
    uploadImg: [],
    uploadFileThumbnail: [],
    addCMS: [],
    isError: false,
    isLoading: false,
  },
  reducers: {
    resetAllCmsData: (state) => {
      state.getCMSBySlug = [];
      state.isError = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetCMS.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetCMS.fulfilled, (state, action) => {
        state.getCMS = action?.payload?.data;
        state.isLoading = false;
      })
      .addCase(actions.GetCMS.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })

      .addCase(actions.GetCMSBySlug.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.GetCMSBySlug.fulfilled, (state, action) => {
        state.getCMSBySlug = action.payload.getCMSBySlug;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(actions.GetCMSBySlug.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })

      .addCase(actions.Uploadfile.fulfilled, (state, action) => {
        state.uploadfile = action.payload.uploadfile;
        state.isLoading = false;
      })
      .addCase(actions.UploadFileThumbnail.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(actions.UploadFileThumbnail.fulfilled, (state, action) => {
        state.uploadFileThumbnail = action.payload.uploadFileThumbnail;
        state.isLoading = false;
      })
      .addCase(actions.UploadFileThumbnail.rejected, (state, action) => {
        state.uploadfile = action.payload;
        state.isLoading = false;
      })
      .addCase(actions.UpdateCms.fulfilled, (state, action) => {
        state.updateCms = action.payload.updateCms;
      })
      .addCase(actions.UploadImg.fulfilled, (state, action) => {
        state.uploadImg = action.payload.uploadImg;
      })
      .addCase(actions.AddCMS.fulfilled, (state, action) => {
        state.addCMS = action.payload.addCMS;
      })
      .addCase(actions.DeleteCMS.fulfilled, (state, action) => {
        state.deleteBlog = action.payload.deleteBlog;
      });
  },
});

export const { resetAllCmsData } = CMSPagesDataSlice.actions;
export default CMSPagesDataSlice.reducer;
