import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseurl, reportApiEndPoint } from "../../configs/serverApiConfig";

// export const GetLoanByReport = createAsyncThunk(
//   "/Loan/GetLoanByReport",
//   async (data, thunkAPI) => {
//     const API = process.env.REACT_APP_API_BASEURL;
//     const accessToken = localStorage.getItem("accessToken");

//     try {
//       const response = await axios.post(
//         `${API}${reportApiEndPoint.GetLoanByReport}`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       return { getLoanByReport: response?.data };
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

export const GetLoanByReport = createAsyncThunk(
  "/Loan/GetLoanByReport",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetLoanByReport}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanByReport: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEmiByReport = createAsyncThunk(
  "/Loan/GetEmiByReport",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetEmiByReport}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getEmiByReport: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetCommission = createAsyncThunk(
  "/Loan/GetCommission",
  async (data, thunkAPI) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.loanBaseURL}${reportApiEndPoint.GetCommission}?page=${
          data?.page
        }&size=${data?.size}&sortBy=${data?.column ?? ""}`,
        data?.date,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCommission: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// export const GetCommission = createAsyncThunk(
//   "/Loan/GetCommission",
//   async (data, thunkAPI) => {
//     const API = process.env.REACT_APP_API_BASEURL;
//     const accessToken = localStorage.getItem("accessToken");

//     try {
//       const response = await axios.post(
//         `${API}${reportApiEndPoint.GetCommission}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       return { getCommission: response?.data };
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );
