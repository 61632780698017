import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { employeeManagementApiEndPoint } from "../../configs/serverApiConfig";

const API = process.env.REACT_APP_API_BASEURL;
const accessToken = localStorage.getItem("accessToken");

export const GetAllUser = createAsyncThunk(
  "/User/GetAllUser",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetAllUser}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAllUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetAllUserById = createAsyncThunk(
  "/User/GetAllUserById",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetAllUserById}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getAllUserById: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLoanDetailsByUser = createAsyncThunk(
  "/Loan/GetLoanDetailsByUser",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetLoanDetailsByUser}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanDetailsByUser: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetNotificationByUserId = createAsyncThunk(
  "notification/byUserID",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetNotificationByUserId}`,
        {
          userid: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response, 'res89898')
      return { getNotificationByUserId: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEmiHistory = createAsyncThunk(
  "/transaction/GetTransactionByUserId",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetTransactionByUserId}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response.data, "resop**");
      return { getEmiHistory: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetEmiCalender = createAsyncThunk(
  "/Loan/GetEmiCalenderByUser",
  async (rowId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API}${employeeManagementApiEndPoint.GetEmiCalenderByUser}`,
        {
          id: rowId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { getEmiCalender: response?.data?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
