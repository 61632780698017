import { createSlice } from "@reduxjs/toolkit";
import * as action from "./loanOfferAction";
export const LoanOfferPagesDataSlice = createSlice({
  name: "LoanOfferPagesData",
  initialState: {
    getLoanOfferData: [],
    getCibilList: [],
    uploadFileThumbnail: [],
    upDateLoanOffer: [],
    addLoanOffer: [],
    addCibilRules: [],
    getLoanOfferById: [],
    deleteLoanOffer: [],
    isLoadingSegment: false,
    isLoadingCibil: false,
    isLoadingAddCibil: false,
    isLoadingAddSegment: false,
  },
  reducers: {
    resetEditLoanOfferDefaultData: (state) => {
      state.getLoanOfferById = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(action.GetLoanOffer.pending, (state) => {
        state.isLoadingSegment = true;
      })
      .addCase(action.GetLoanOffer.fulfilled, (state, action) => {
        state.getLoanOfferData = action.payload.getLoanOfferData;
        state.isLoadingSegment = false;
      })
      .addCase(action.GetCibilList.pending, (state) => {
        state.isLoadingCibil = true;
      })
      .addCase(action.GetCibilList.fulfilled, (state, action) => {
        state.getCibilList = action.payload.getCibilList;
        state.isLoadingCibil = false;
      })

      .addCase(action.GetLoanOfferById.fulfilled, (state, action) => {
        state.getLoanOfferById = action.payload.getLoanOfferById;
      })
      .addCase(action.UploadFileThumbnail.fulfilled, (state, action) => {
        state.uploadFileThumbnail = action.payload.uploadFileThumbnail;
      })

      .addCase(action.AddLoanOffer.pending, (state) => {
        state.isLoadingAddSegment = true;
      })
      .addCase(action.AddLoanOffer.fulfilled, (state, action) => {
        state.addLoanOffer = action.payload.addLoanOffer;
        state.isLoadingAddSegment = false;
      })
      .addCase(action.AddLoanOffer.rejected, (state) => {
        state.isLoadingAddSegment = false;
      })
      .addCase(action.AddCibilRules.pending, (state) => {
        state.isLoadingAddCibil = true;
      })
      .addCase(action.AddCibilRules.fulfilled, (state, action) => {
        state.addCibilRules = action.payload.addCibilRules;
        state.isLoadingAddCibil = false;
      })
      .addCase(action.AddCibilRules.rejected, (state) => {
        state.isLoadingAddCibil = false;
      })

      .addCase(action.UpDateLoanOffer.fulfilled, (state, action) => {
        state.upDateLoanOffer = action.payload.upDateLoanOffer;
      })
      .addCase(action.DeleteLoanOffer.fulfilled, (state, action) => {
        state.deleteLoanOffer = action.payload.deleteLoanOffer;
      });
  },
});
export const { resetEditLoanOfferDefaultData } =
  LoanOfferPagesDataSlice.actions;
export default LoanOfferPagesDataSlice.reducer;
