import styles from "./loader.module.css";
import logo from "../../assets/images/logo/MoneyPlant-LOGO.png";
const Loader = () => {
  return (
    <>
      <span
        className="d-flex  flex-column align-items-center justify-content-center "
        style={{ height: "50vh" }}
      >
        <span className={styles.loading} />

        <img src={logo} width={70} alt="loader" style={{ marginTop: "15px" }} />
      </span>
    </>
  );
};

export default Loader;
