import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  LendingPartnersApiEndPoint,
  baseurl,
} from "../../configs/serverApiConfig";
const accessToken = localStorage.getItem("accessToken");
export const GetLendingPartner = createAsyncThunk(
  "/CMS/GetLendingPartner",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${LendingPartnersApiEndPoint.Get}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLendingPartner: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLendingPartnerByID = createAsyncThunk(
  "/CMS/GetLendingPartnerByID",
  async (data) => {
    try {
      const response = await axios.get(
        `${baseurl?.adminServiceBaseURl}${LendingPartnersApiEndPoint.GetLendingPartnersById}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLendingPartnerByID: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const AddPartner = createAsyncThunk(
  "/CMS/AddPartner",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl?.adminServiceBaseURl}${LendingPartnersApiEndPoint.Add}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { addPartner: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const UpDateLendingPartner = createAsyncThunk(
  "/CMS/UpDateLendingPartner",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseurl?.adminServiceBaseURl}${LendingPartnersApiEndPoint.Update}/${data?.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // 'Content-Type': 'multipart/form-data' // Set the content type explicitly
          },
        }
      );
      return { upDateLendingPartner: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const DeleteLendingPartner = createAsyncThunk(
  "/CMS/DeleteLendingPartner",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${baseurl?.adminServiceBaseURl}${LendingPartnersApiEndPoint.DeleteLendingPartners}/${data}`,
        {
          data: { id: data }, // Pass the id in the request body
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { deleteLendingPartner: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
