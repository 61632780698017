// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./auth/authSlice";
import DashBoardDataSlice from "./dashboard/dashboardSlice";
import companyMangeTableData from "./companyManagement/companyManagementSlice";
import EmployeeManagementData from "./employeeManagement/employeeManagementSlice";
import SendNotificationData from "./sendNotification/sendNotificationSlice";
import profileManagement from "./profileManagement/profileManagementSlice";
import ReportData from "./report/reportSlice";
import LoanManagementData from "./loanManagement/loanManagementSlice";
import CMSPagesData from "./cms/cmsSlice";
import BlogPagesData from "./blog/blogSlice";
import SettingPagesData from "./settings/settingSlice";
import AdvertisementPagesData from "./advertisement/advertisementSlice";
import NewsPagesData from "./news/newsSlice";
import LoanOfferPagesData from "./loanOffer/loanOfferSlice";
import LendingPartnerPagesData from "./lendingPartner/lendingPartnerSlice";
import BankPagesData from "./bank/bankSlice";
import authenticationData from "../pages/auth/authentication/store";
import ForgotPassWordData from "../pages/auth/forgotpassword/store";

const rootReducer = {
  auth,
  navbar,
  layout,
  DashBoardDataSlice,
  companyMangeTableData,
  EmployeeManagementData,
  ReportData,
  SendNotificationData,
  profileManagement,
  LoanManagementData,
  CMSPagesData,
  BlogPagesData,
  SettingPagesData,
  AdvertisementPagesData,
  NewsPagesData,
  LendingPartnerPagesData,
  LoanOfferPagesData,
  authenticationData,
  ForgotPassWordData,
  BankPagesData,
};

export default rootReducer;
