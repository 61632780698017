import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  accessToken,
  baseurl,
  loanManagementApiEndPoint,
} from "../../configs/serverApiConfig";
// import { handleLogout } from "@store/auth/authSlice";
const fetchData = async (url, data, thunkAPI) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

export const GetAllLoanData = createAsyncThunk(
  "/Loan/GetLoanByReport",
  async (data, thunkAPI) => {
    const formData = new FormData();
    formData.append("startdate", data.date1);
    formData.append("enddate", data.date2);
    return fetchData(
      `${baseurl.loanManagementBaseBaseURL}${loanManagementApiEndPoint.GetLoanByReport}`,
      formData,
      thunkAPI
    );
  }
);

export const GetEMIHistory = createAsyncThunk(
  "/transaction/GetTransactionByUserId",
  async (data, thunkAPI) => {
    const payload = { id: data };
    return fetchData(
      `${baseurl.loanManagementBaseBaseURL}${loanManagementApiEndPoint.GetTransactionByUserId}`,
      payload,
      thunkAPI
    );
  }
);

export const GetLoanDetailsByLoanId = createAsyncThunk(
  "/Loan/GetLoanDetailsByLoanId",
  async (data, thunkAPI) => {
    const payload = { id: data };
    return fetchData(
      `${baseurl.loanManagementBaseBaseURL}${loanManagementApiEndPoint.GetLoanDetailsByLoanId}`,
      payload,
      thunkAPI
    );
  }
);

export const GetEmiCalenderByLoanAndUser = createAsyncThunk(
  "/Loan/GetEmiCalenderByLoanAndUser",
  async (data, thunkAPI) => {
    return fetchData(
      `${baseurl.loanManagementBaseBaseURL}${loanManagementApiEndPoint.GetEmiCalenderByLoanAndUser}`,
      data,
      thunkAPI
    );
  }
);

export const GetTransactionByUserIdAndLoan = createAsyncThunk(
  "/transaction/GetTransactionByUserIdAndLoan",
  async (data, thunkAPI) => {
    return fetchData(
      `${baseurl.loanManagementBaseBaseURL}${loanManagementApiEndPoint.GetTransactionByUserIdAndLoan}`,
      data,
      thunkAPI
    );
  }
);
export const GetLoanList = createAsyncThunk(
  "loan/GetLoanList",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanManagementApiEndPoint?.GetLoanList}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response?.status, "response");
      return { getLoanList: response?.data };
    } catch (error) {
      // console.log(error, "error");
      if (error?.response.status === 401) {
        // console.log("helloLogOut");
        // thunkAPI.dispatch(handleLogout());
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanDetailsByUserID = createAsyncThunk(
  "loan/GetLoanDetailsByUserID",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanManagementApiEndPoint.GetLoanDetailsByLoanId}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { data: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanEMIHistory = createAsyncThunk(
  "loan/GetLoanEMIHistory",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanManagementApiEndPoint?.GetLoanEMIHistoryEnd}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanEMIHistory: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLoanEMICalender = createAsyncThunk(
  "loan/GetLoanEMICalender",
  async (virtualId, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseurl.paymentBaseURL}${loanManagementApiEndPoint?.GetLoanEMICalenderEnd}/${virtualId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanEMICalender: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetLoanAccountNumberByUserID = createAsyncThunk(
  "loan/GetLoanAccountNumberByUserID",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${baseurl.loanBaseURL}${loanManagementApiEndPoint.GetLoanAccountNumberById}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLoanAccountNumberByUserID: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
