import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./settingAction";

export const SettingsPagesDataSlice = createSlice({
  name: "SettingsPagesData",
  initialState: {
    getSettings: [],
    addSettings: [],
    getSettingsBySlug: [],
    upDateSettings: [],
    deleteSettings: [],
    uploadFileThumbnail: [],
    loading: false,
    isError: false,
  },
  reducers: {
    resetErrorSettings: (state) => {
      state.isError = false;
    },
    resetAddSettings: (state) => {
      state.addSettings = [];
    },
    resetUpdateSettings: (state) => {
      state.upDateSettings = [];
    },

    resetEditSettingsData: (state) => {
      state.getSettingsBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetSettings.fulfilled, (state, action) => {
        state.getSettings = action.payload.getSettings;
        state.loading = false;
      })
      .addCase(actions.GetSettings.rejected, (state) => {
        state.isError = true;
        state.loading = false;
      })

      .addCase(actions.GetSettingsBySlug.fulfilled, (state, action) => {
        state.getSettingsBySlug = action.payload.getSettingsBySlug;
      })

      .addCase(actions.AddSettings.fulfilled, (state, action) => {
        state.addSettings = action.payload.addSettings;
      })

      .addCase(actions.UpDateSettings.fulfilled, (state, action) => {
        state.upDateSettings = action.payload.upDateSettings;
      })

      .addCase(actions.DeleteSettings.fulfilled, (state, action) => {
        state.deleteSettings = action.payload.deleteSettings;
      });
  },
});
export const { resetAddSettings } = SettingsPagesDataSlice.actions;
export const { resetErrorSettings } = SettingsPagesDataSlice.actions;
export const { resetUpdateSettings } = SettingsPagesDataSlice.actions;
export const { resetEditSettingsData } = SettingsPagesDataSlice.actions;
export default SettingsPagesDataSlice.reducer;
