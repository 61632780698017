
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const SignInCompany = createAsyncThunk('Admin/SignInCompany', async (data, thunkAPI) => {
  const API = process.env.REACT_APP_API_BASEURL


  try {
    const response = await axios.post(`${API}Admin/SignInCompany`, data)
   
  return { signInCompany: response?.data}
  } catch (error) {
     return thunkAPI.rejectWithValue(error.response.data) 
  }
})


export const authenticationDataSlice = createSlice({
  name: 'authenticationData',
  initialState: {
    signInCompany:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(SignInCompany.fulfilled, (state, action) => {
      state.signInCompany = action.payload.signInCompany
      })
  }
})

export default authenticationDataSlice.reducer
