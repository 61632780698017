import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./bankAction";

export const BankPagesDataSlice = createSlice({
  name: "BankPagesData",
  initialState: {
    getBank: [],
    addBank: [],
    getBankBySlug: [],
    upDateBank: [],
    deleteBank: [],
    uploadFileThumbnail: [],
    loading: false,
  },
  reducers: {
    resetAddBank: (state) => {
      state.addBank = [];
    },
    resetUpdateBank: (state) => {
      state.upDateBank = [];
    },

    resetEditBankData: (state) => {
      state.getBankBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetBank.fulfilled, (state, action) => {
        state.getBank = action.payload.getBank;
        state.loading = false;
      })
      .addCase(actions.GetBank.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(actions.GetBankBySlug.fulfilled, (state, action) => {
        state.getBankBySlug = action.payload.getBankBySlug;
      })

      .addCase(actions.AddBank.fulfilled, (state, action) => {
        state.addBank = action.payload.addBank;
      })

      .addCase(actions.UpDateBank.fulfilled, (state, action) => {
        state.upDateBank = action.payload.upDateBank;
      })

      .addCase(actions.DeleteBank.fulfilled, (state, action) => {
        state.deleteBank = action.payload.deleteBank;
      });
  },
});
export const { resetAddBank } = BankPagesDataSlice.actions;
export const { resetUpdateBank } = BankPagesDataSlice.actions;
export const { resetEditBankData } = BankPagesDataSlice.actions;
export default BankPagesDataSlice.reducer;
